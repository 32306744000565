import {
  Alert,
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Select,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { BiReset } from 'react-icons/bi';
import { MdDelete, MdEdit } from 'react-icons/md';
import { Link, useLocation } from 'react-router-dom';
import BackButton from '../../components/BackButton';
import CreatorLink from '../../components/CreatorLink';
import TabInfo from '../../components/TabInfo';
import {
  constants,
  deletes,
  getStatusLabelById,
  monthsMapping,
  status,
} from '../../constants';
import { useAuthContext } from '../../context';
import {
  useCreateIncomeMutation,
  useDeleteIncomeOrIncomesMutation,
  useLazyGetIncomesQuery,
  useUpdateIncomeOrIncomesMutation,
} from '../../features/apiSlice';
import {
  dateTimeFormatter,
  makeFiltersQueryStringFromFiltersObjects,
  range,
  removeWhiteSpacesFromString,
  validateMessages,
} from '../../utilities';

const Incomes = () => {
  let { search: urlQuery } = useLocation();
  urlQuery = urlQuery.replace('?', '&');

  const [tableParams, setTableParams] = useState({
    pagination: { current: 1, pageSize: constants.PER_PAGE.income },
    filters: {},
    sorter: {},
  });
  const {
    pagination: { current, pageSize },
    filters,
    sorter,
  } = tableParams;

  const [queryString, setQueryString] = useState({
    search: '',
    filter: '',
    sort: '',
  });
  const { search, filter, sort } = queryString;

  const columns = [
    {
      title: 'S.No',
      width: 100,
      dataIndex: 's.no',
      key: 's.no',
      fixed: 'left',
      render: (_, row, index) => sNo[index],
    },
    {
      title: 'Id',
      width: 100,
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
    },
    {
      title: 'Amount',
      width: 150,
      dataIndex: 'amount',
      key: 'amount',
      sortOrder: sorter?.columnKey === 'amount' ? sorter?.order : null,
      sorter: (a, b, sortOrder) => { },
    },
    {
      title: 'Fees Status',
      width: 150,
      dataIndex: 'status',
      key: 'status',
      filters: status.map(({ value, label }) => ({ value, text: label })),
      filterMode: 'tree',
      filterSearch: true,
      filteredValue: filters?.status || null,
      render: (status) => getStatusLabelById(status),
    },
    {
      title: 'Which Month Fee',
      dataIndex: 'whichMonthFee',
      key: 'whichMonthFee',
      width: "12.5rem",
      render: (whichMonthFee) => whichMonthFee || "---",
    },
    {
      title: 'Passenger',
      dataIndex: 'passenger',
      key: 'passenger',
      render: (passenger) => {
        if (passenger !== null) {
          const { id, name, phoneNumber, isDeleted } = passenger;
          return (
            <Link
              to={`/passengers?id=${id}`}
              className={isDeleted ? 'text-[red]' : 'text-[#1677ff]'}
            >
              {`${name} / ${phoneNumber}`}
            </Link>
          );
        } else {
          return null;
        }
      },
    },
    {
      title: 'Created By',
      dataIndex: 'creator',
      key: 'creator',
      render: (creator) => <CreatorLink creator={creator} />,
    },
    {
      title: 'Deleted',
      width: 150,
      dataIndex: 'isDeleted',
      key: 'isDeleted',
      filters: deletes,
      filterMode: 'tree',
      filterSearch: true,
      filteredValue: filters?.isDeleted || null,
      render: (isDeleted) => String(isDeleted),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => dateTimeFormatter(createdAt),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt) => dateTimeFormatter(updatedAt),
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      width: 150,
      render: (_, row) => {
        if (roleHasAccess) {
          const isDisabled = selectedRowKeys.length > 0 ? true : false;
          return (
            <div className='flex items-center gap-4'>
              <Tooltip title="Edit">
                <Typography.Link
                  onClick={() => {
                    createOrUpdate('Update', row);
                    setUpdatedRow(row);
                    setSelectedRowKeys([]);
                  }}
                >
                  <MdEdit size={20} />
                </Typography.Link>
              </Tooltip>

              {row.isDeleted ? (
                <Tooltip title="Recover">
                  <Typography.Link
                    disabled={isDisabled}
                    onClick={() => onRemove(row.id, 'recover')}
                  >
                    <BiReset size={20} className='text-[#52c41a]' />
                  </Typography.Link>
                </Tooltip>
              ) : (
                <Tooltip title="Delete">
                  <Typography.Link
                    disabled={isDisabled}
                    onClick={() => onRemove(row.id, 'delete')}
                  >
                    <MdDelete size={20} className='text-[red]' />
                  </Typography.Link>
                </Tooltip>
              )}
            </div>
          );
        }
      },
    },
  ];

  const { user } = useAuthContext();
  const roleHasAccess = user?.data?.role == '2';
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [query, setQuery] = useState('');
  const [sNo, setSNo] = useState([]);
  const [pageTitle, setPageTitle] = useState('Incomes');
  const [modalTitle, setModalTitle] = useState('');
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [fields, setFields] = useState();
  const [updatedRow, setUpdatedRow] = useState(null);

  const [
    getAll,
    {
      data: getAllData,
      isLoading: getAllIsLoading,
      isFetching: getAllIsFetching,
    },
  ] = useLazyGetIncomesQuery();

  const [create, { data: createData, isLoading: createIsLoading }] =
    useCreateIncomeMutation();

  const [update, { data: updateData, isLoading: updateIsLoading }] =
    useUpdateIncomeOrIncomesMutation();

  const [remove, { data: removeData, isLoading: removeIsLoading }] =
    useDeleteIncomeOrIncomesMutation();

  useEffect(() => {
    current === 1
      ? setSNo(range(current, pageSize + 1))
      : setSNo(range((current - 1) * pageSize + 1, current * pageSize + 1));

    getAll(
      `?page=${current}&per_page=${pageSize}${urlQuery}${search ? `&search=${search}` : ''
      }${filter ? filter : ''}${sort ? sort : '&sort=updatedAt&sort_direction=desc'}`
    );
  }, [urlQuery, current, pageSize, search, filter, sort, getAll]);

  useEffect(() => {
    if (getAllData?.isSuccess) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
        },
      });
    }
  }, [JSON.stringify(getAllData)]);

  useEffect(() => {
    if (createData?.isSuccess) {
      openNotificationWithIcon('success', createData?.message);
      closeModal();
      clear();
    } else if (createData?.isError) {
      openNotificationWithIcon('error', createData?.message);
    }
  }, [JSON.stringify(createData)]);

  useEffect(() => {
    if (updateData?.isSuccess) {
      openNotificationWithIcon('success', updateData?.message);
      closeModal();
      clear();
    } else if (updateData?.isError) {
      openNotificationWithIcon('error', updateData?.message);
    }
  }, [JSON.stringify(updateData)]);

  useEffect(() => {
    if (removeData?.isSuccess) {
      openNotificationWithIcon('success', removeData?.message);
      setSelectedRowKeys([]);
    } else if (removeData?.isError) {
      openNotificationWithIcon('error', removeData?.message);
    }
  }, [JSON.stringify(removeData)]);

  const onSelectChange = (newSelectedRowKeys) =>
    setSelectedRowKeys(newSelectedRowKeys);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  const openModal = (modalTitle) => {
    setModalTitle(modalTitle);
    setIsModelOpen(true);
  };

  const closeModal = () => {
    setIsModelOpen(false);
    // form.resetFields();
  };

  const clear = () => {
    setQueryString({ ...queryString, search: '' });
    setQuery('');
  };

  const onFinish = async (values) => {
    try {
      values = removeWhiteSpacesFromString(values);
      if (modalTitle === 'Create') {
        await create(values);
      } else {
        await update({ ...values, idOrIds: updatedRow?.id });
      }
    } catch (error) {
      console.log('error', error, error.message);
      openNotificationWithIcon(
        'error',
        error.message || 'Something went wrong!'
      );
    }
  };

  const onRemove = async (id, action) => {
    try {
      if (window.confirm(`Are you sure you want to ${action || 'delete'}${id ? ' id ' + id + ' record' : ' all selected records'}?`)) {
        if (id && action) {
          await remove({ idOrIds: id, action });
        } else {
          await remove({ idOrIds: selectedRowKeys, action: 'delete' });
        }
      }
    } catch (error) {
      console.log('error', error, error.message);
      openNotificationWithIcon(
        'error',
        error.message || 'Something went wrong!'
      );
    }
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    setTableParams({ pagination, filters, sorter });

    // sorting
    if (extra.action !== 'paginate') {
      if (sorter?.order) {
        const sortString = `&sort=${sorter.field}&sort_direction=${sorter.order === 'ascend' ? 'asc' : 'desc'
          }`;
        setQueryString({ ...queryString, sort: sortString });
      } else {
        setQueryString({ ...queryString, sort: '' });
      }
    }

    // filtering
    if (extra.action === 'filter') {
      setQueryString({
        ...queryString,
        filter: makeFiltersQueryStringFromFiltersObjects(filters),
      });
    }
  };

  const onSearch = (value) => {
    value = value.trim();
    setQueryString({
      ...queryString,
      search: encodeURIComponent(value),
    });
    if (value) {
      setPageTitle('Search Results:');
    } else {
      setPageTitle('Incomes');
    }
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
  };

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      api[type]({
        message: 'Success' || type,
        description: message || 'Success!',
      });
    } else if (type === 'error') {
      api[type]({
        message: 'Error' || type,
        description: message || 'Something went wrong!',
      });
    }
  };

  const createOrUpdate = (title, row) => {
    openModal(title);
    setFields([
      {
        name: ['amount'],
        value: row ? row.amount : '',
      },
      {
        name: ['status'],
        value: row ? row.status : '',
      },
      {
        name: ['passengerId'],
        value: row ? row.passenger?.id : '',
      },
    ]);
  };

  const clearAllFiltersAndSorters = () => {
    setTableParams({ ...tableParams, filters: {}, sorter: {} });
    setQueryString({ ...queryString, filter: '', sort: '' });
  };

  return (
    <>
      {contextHolder}
      <TabInfo title='Incomes' />
      <div className='flex justify-between items-center'>
        <h1 className='text-3xl font-bold tracking-tight text-gray-900'>
          {pageTitle}
        </h1>
        <div className='flex items-center gap-4'>
          {search ? (
            <BackButton

              onClick={() => {
                setPageTitle('Incomes');
                clear();
              }}
            />
          ) : null}

          <Tooltip placement='left' title={'Clear all filters and sorters'}>
            <Button
              type='primary'
              size='large'

              danger
              onClick={clearAllFiltersAndSorters}
            >
              Clear
            </Button>
          </Tooltip>

          {roleHasAccess && (
            <Button
              className='mr-2'
              size='large'
              type='primary'
              onClick={() => createOrUpdate('Create', null)}
            >
              Create
            </Button>
          )}
          <Tooltip title="Search by passengerId, amount, ID, createdBy ID!">
            <Input.Search
              placeholder='Search by passengerId, amount, ID, createdBy ID!'
              enterButton='Search'
              size='large'
              allowClear
              onSearch={onSearch}
              onChange={(e) => setQuery(e.target.value)}
              value={query}
            />
          </Tooltip>
        </div>
      </div>

      <div className='mt-8'>
        {!getAllIsLoading && getAllData?.isError ? (
          <Alert
            message='Error'
            description={getAllData?.message || 'Something went wrong!'}
            type='error'
            showIcon
          />
        ) : (
          !getAllIsLoading &&
          getAllData?.isSuccess && (
            <>
              {/* {roleHasAccess && (
                <>
                  <div className='mb-4'>
                    <Button type='primary' danger onClick={() => onRemove(undefined, "delete")} disabled={!selectedRowKeys.length > 0} loading={removeIsLoading} size='large'>
                      Delete
                    </Button>
                    {selectedRowKeys.length > 0 && <span className='ml-2'>Selected {selectedRowKeys.length} rows</span>}
                  </div>
                </>
              )} */}
              <Table
                style={{ width: 'auto' }}
                // sortDirections={['ascend', 'descend', 'ascend']} // to prevent sorter back to default status and if we use sortDirections props on the table then it will affect for all table columns
                // rowSelection={rowSelection}
                columns={columns}
                rowKey={(record) => record?.id}
                dataSource={getAllData?.data}
                scroll={{
                  x: 'max-content',
                  y: '90vh',
                  scrollToFirstRowOnChange: true,
                }}
                bordered
                loading={getAllIsLoading || getAllIsFetching}
                pagination={{
                  ...tableParams.pagination,
                  pageSizeOptions: range(
                    constants.PER_PAGE.income,
                    constants.PER_PAGE.income * 11,
                    constants.PER_PAGE.income
                  ),
                  responsive: true,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                  hideOnSinglePage: true,
                  total: getAllData?.total,
                  position: ['topRight'],
                }}
                onChange={onTableChange}
                title={() => (
                  <div className='text-lg'>
                    Total Amount:
                    <span className='font-bold'>
                      {' '}
                      {new Intl.NumberFormat().format(
                        getAllData?.data.reduce(
                          (acc, row) => acc + row.amount,
                          0
                        )
                      )}
                    </span>
                  </div>
                )}
              // footer={() => <></>}
              />
            </>
          )
        )}
      </div>

      <Modal
        title={modalTitle}
        centered
        open={isModelOpen}
        onCancel={closeModal}
        footer={null}
        width={1024}
      >
        <Form
          form={form}
          fields={fields}
          size='large'
          layout='vertical'
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <div className='grid grid-cols-12 gap-4 mb-6'>
            <div className='col-span-12 md:col-span-6'>
              <Form.Item
                className='mb-0'
                label='Amount'
                name='amount'
                rules={[{ required: true }]}
              >
                <InputNumber
                  min={0}
                  className='w-full'
                  placeholder='Enter amount!'
                />
              </Form.Item>
            </div>
            <div className='col-span-12 md:col-span-6'>
              <Form.Item
                className='mb-0'
                label='Passenger Id'
                name='passengerId'
                rules={[{ required: true }]}
              >
                <InputNumber
                  min={1}
                  className='w-full'
                  placeholder='Enter passenger Id!'
                />
              </Form.Item>
            </div>
            <div className='col-span-12 md:col-span-6'>
              <Form.Item
                className='mb-0'
                label='Status'
                name='status'
                rules={[{ required: true }]}
              >
                <Select placeholder='Select status!' options={status} />
              </Form.Item>
            </div>
          </div>
          <Form.Item className='flex justify-end mb-0'>
            <Button htmlType='button' className='mr-2' onClick={closeModal}>
              Cancel
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              loading={createIsLoading || updateIsLoading}
            >
              {modalTitle}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Incomes;
