import {
  Alert,
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  notification,
  Select,
  Table,
  Tooltip,
  Typography,
} from 'antd';
import { useEffect, useState } from 'react';
import { BiReset } from 'react-icons/bi';
import { CgUnblock } from 'react-icons/cg';
import { MdBlock, MdDelete, MdEdit, MdRemoveRedEye } from 'react-icons/md';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import BackButton from '../../components/BackButton';
import CreatorLink from '../../components/CreatorLink';
import TabInfo from '../../components/TabInfo';
import {
  blocks,
  constants,
  deletes,
  genders,
  getGenderLabelById,
  getRoleLabelById,
  getStateLabelById,
  roles,
} from '../../constants';
import { useAuthContext } from '../../context';
import {
  useBlockOrUnBlockUserMutation,
  useDeleteUserOrUsersMutation,
  useLazyGetUsersQuery,
  useRegisterMutation,
  useUpdateUserOrUsersMutation,
} from '../../features/apiSlice';
import {
  dateTimeFormatter,
  filterOption,
  getModifiedCities,
  makeFiltersQueryStringFromFiltersObjects,
  range,
  removeWhiteSpacesFromString,
  validateMessages,
} from '../../utilities';

const Users = () => {
  let { search: urlQuery } = useLocation();
  urlQuery = urlQuery.replace('?', '&');
  const { error, cities } = useSelector((state) => state.city);

  const [tableParams, setTableParams] = useState({
    pagination: { current: 1, pageSize: constants.PER_PAGE.vehicle },
    filters: {},
    sorter: {},
  });
  const {
    pagination: { current, pageSize },
    filters,
    sorter,
  } = tableParams;

  const [queryString, setQueryString] = useState({
    search: '',
    filter: '',
    sort: '',
  });
  const { search, filter, sort } = queryString;

  const columns = [
    {
      title: 'S.No',
      width: 100,
      dataIndex: 's.no',
      key: 's.no',
      fixed: 'left',
      render: (_, row, index) => sNo[index],
    },
    {
      title: 'Id',
      width: 100,
      dataIndex: 'id',
      key: 'id',
      fixed: 'left',
    },
    {
      title: 'Driver Name',
      width: 150,
      dataIndex: 'driverName',
      key: 'driverName',
      sortOrder: sorter?.columnKey === 'driverName' ? sorter?.order : null,
      sorter: (a, b, sortOrder) => { },
    },
    {
      title: 'Transporter Name',
      width: 200,
      dataIndex: 'transporterName',
      key: 'transporterName',
    },
    {
      title: 'Username',
      width: 150,
      dataIndex: 'username',
      key: 'username',
    },
    {
      title: 'Income',
      width: 100,
      dataIndex: 'income',
      key: 'income',
      render: (income) => new Intl.NumberFormat().format(income),
    },
    {
      title: 'Expense',
      width: 100,
      dataIndex: 'expense',
      key: 'expense',
      render: (expense) => new Intl.NumberFormat().format(expense),
    },
    {
      title: 'Role',
      width: 150,
      dataIndex: 'role',
      key: 'role',
      filters: roles.map(({ value, label }) => ({ value, text: label })),
      filterMode: 'tree',
      filterSearch: true,
      filteredValue: filters?.role || null,
      render: (role) => getRoleLabelById(role),
    },
    {
      title: 'Gender',
      width: 150,
      dataIndex: 'gender',
      key: 'gender',
      filters: genders.map(({ value, label }) => ({ value, text: label })),
      filterMode: 'tree',
      filterSearch: true,
      filteredValue: filters?.gender || null,
      render: (gender) => getGenderLabelById(gender),
    },
    {
      title: 'City',
      width: 200,
      dataIndex: 'city',
      key: 'cityId',
      filters: error ? [] : getModifiedCities(cities, 'text'),
      filterMode: 'tree',
      filterSearch: true,
      filteredValue: filters?.cityId || null,
      render: (city) => {
        if (city !== null) {
          const { id, name, state, isDeleted } = city;
          const nameWithState = `${name} / ${getStateLabelById(state)}`;
          return (
            <Link
              to={`/cities?id=${id}`}
              className={isDeleted ? 'text-[red]' : 'text-[#1677ff]'}
            >
              {nameWithState}
            </Link>
          );
        } else {
          return null;
        }
      },
    },
    {
      title: 'Created By',
      width: 200,
      dataIndex: 'creator',
      key: 'creator',
      render: (creator) => <CreatorLink creator={creator} />,
    },
    {
      title: 'Passengers',
      width: 125,
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (createdBy, row) => (
        <Link
          to={`/passengers?createdBy=${row.id}`}
          className='flex justify-center'
        >
          <MdRemoveRedEye size={20} className='text-[#1677ff]' />
        </Link>
      ),
    },
    {
      title: 'Incomes',
      width: 125,
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (createdBy, row) => (
        <Link
          to={`/incomes?createdBy=${row.id}`}
          className='flex justify-center'
        >
          <MdRemoveRedEye size={20} className='text-[#1677ff]' />
        </Link>
      ),
    },
    {
      title: 'Expenses',
      width: 125,
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (createdBy, row) => (
        <Link
          to={`/expenses?createdBy=${row.id}`}
          className='flex justify-center'
        >
          <MdRemoveRedEye size={20} className='text-[#1677ff]' />
        </Link>
      ),
    },
    {
      title: 'Events',
      width: 125,
      dataIndex: 'createdBy',
      key: 'createdBy',
      render: (createdBy, row) => (
        <Link
          to={`/events?createdBy=${row.id}`}
          className='flex justify-center'
        >
          <MdRemoveRedEye size={20} className='text-[#1677ff]' />
        </Link>
      ),
    },

    {
      title: 'Blocked',
      width: 150,
      dataIndex: 'isActive',
      key: 'isActive',
      filters: blocks,
      filterMode: 'tree',
      filterSearch: true,
      filteredValue: filters?.isActive || null,
      render: (isActive) => String(isActive),
    },
    {
      title: 'Deleted',
      width: 150,
      dataIndex: 'isDeleted',
      key: 'isDeleted',
      filters: deletes,
      filterMode: 'tree',
      filterSearch: true,
      filteredValue: filters?.isDeleted || null,
      render: (isDeleted) => String(isDeleted),
    },
    {
      title: 'Created At',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (createdAt) => dateTimeFormatter(createdAt),
    },
    {
      title: 'Updated At',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (updatedAt) => dateTimeFormatter(updatedAt),
    },
    {
      title: 'Actions',
      key: 'actions',
      fixed: 'right',
      width: 150,
      render: (_, row) => {
        if (roleHasAccess) {
          const isDisabled = selectedRowKeys.length > 0 ? true : false;
          return (
            <div className='flex items-center gap-4'>
              <Tooltip title="Edit">
                <Typography.Link
                  onClick={() => {
                    createOrUpdate('Update', row);
                    setUpdatedRow(row);
                    setSelectedRowKeys([]);
                  }}
                >
                  <MdEdit size={20} />
                </Typography.Link>
              </Tooltip>

              {row.isDeleted ? (
                <Tooltip title="Recover">
                  <Typography.Link
                    disabled={isDisabled}
                    onClick={() => onRemove(row.id, 'recover')}
                  >
                    <BiReset size={20} className='text-[#52c41a]' />
                  </Typography.Link>
                </Tooltip>
              ) : (
                <Tooltip title="Delete">
                  <Typography.Link
                    disabled={isDisabled}
                    onClick={() => onRemove(row.id, 'delete')}
                  >
                    <MdDelete size={20} className='text-[red]' />
                  </Typography.Link>
                </Tooltip>
              )}
              {row.isActive ? (
                <Tooltip title="Block">
                  <Typography.Link
                    disabled={isDisabled}
                    onClick={() => onBlockOrUnblock(row.id, 'block')}
                  >
                    <MdBlock size={20} className='text-[red]' />
                  </Typography.Link>
                </Tooltip>

              ) : (
                <Tooltip title="Unblock">
                  <Typography.Link
                    disabled={isDisabled}
                    onClick={() => onBlockOrUnblock(row.id, 'unblock')}
                  >
                    <CgUnblock size={20} className='text-[#52c41a]' />
                  </Typography.Link>
                </Tooltip>
              )}
            </div>
          );
        }
      },
    },
  ];

  const { user } = useAuthContext();
  const roleHasAccess = user?.data?.role == '2';
  const [api, contextHolder] = notification.useNotification();
  const [form] = Form.useForm();
  const [selectedRowKeys, setSelectedRowKeys] = useState([]);
  const [query, setQuery] = useState('');
  const [sNo, setSNo] = useState([]);
  const [pageTitle, setPageTitle] = useState('Users');
  const [modalTitle, setModalTitle] = useState('');
  const [isModelOpen, setIsModelOpen] = useState(false);
  const [fields, setFields] = useState();
  const [updatedRow, setUpdatedRow] = useState(null);

  const [
    getAll,
    {
      data: getAllData,
      isLoading: getAllIsLoading,
      isFetching: getAllIsFetching,
    },
  ] = useLazyGetUsersQuery();

  const [create, { data: createData, isLoading: createIsLoading }] =
    useRegisterMutation();

  const [update, { data: updateData, isLoading: updateIsLoading }] =
    useUpdateUserOrUsersMutation();

  const [remove, { data: removeData, isLoading: removeIsLoading }] =
    useDeleteUserOrUsersMutation();

  const [block, { data: blockData, isLoading: blockIsLoading }] =
    useBlockOrUnBlockUserMutation();

  useEffect(() => {
    current === 1
      ? setSNo(range(current, pageSize + 1))
      : setSNo(range((current - 1) * pageSize + 1, current * pageSize + 1));

    getAll(
      `?page=${current}&per_page=${pageSize}${urlQuery}${search ? `&search=${search}` : ''
      }${filter ? filter : ''}${sort ? sort : '&sort=updatedAt&sort_direction=desc'}`
    );
  }, [urlQuery, current, pageSize, search, filter, sort, getAll]);

  useEffect(() => {
    if (getAllData?.isSuccess) {
      setTableParams({
        ...tableParams,
        pagination: {
          ...tableParams.pagination,
        },
      });
    }
  }, [JSON.stringify(getAllData)]);


  useEffect(() => {
    if (createData?.isSuccess) {
      openNotificationWithIcon('success', createData?.message);
      closeModal();
      clear();
    } else if (createData?.isError) {
      openNotificationWithIcon('error', createData?.message);
    }
  }, [JSON.stringify(createData)]);

  useEffect(() => {
    if (updateData?.isSuccess) {
      openNotificationWithIcon('success', updateData?.message);
      closeModal();
      clear();
    } else if (updateData?.isError) {
      openNotificationWithIcon('error', updateData?.message);
    }
  }, [JSON.stringify(updateData)]);

  useEffect(() => {
    if (removeData?.isSuccess) {
      openNotificationWithIcon('success', removeData?.message);
      setSelectedRowKeys([]);
    } else if (removeData?.isError) {
      openNotificationWithIcon('error', removeData?.message);
    }
  }, [JSON.stringify(removeData)]);

  useEffect(() => {
    if (blockData?.isSuccess) {
      openNotificationWithIcon('success', blockData?.message);
      setSelectedRowKeys([]);
    } else if (blockData?.isError) {
      openNotificationWithIcon('error', blockData?.message);
    }
  }, [JSON.stringify(blockData)]);

  const onSelectChange = (newSelectedRowKeys) =>
    setSelectedRowKeys(newSelectedRowKeys);

  const rowSelection = {
    selectedRowKeys,
    onChange: onSelectChange,
    preserveSelectedRowKeys: true,
  };

  const openModal = (modalTitle) => {
    setModalTitle(modalTitle);
    setIsModelOpen(true);
  };

  const closeModal = () => {
    setIsModelOpen(false);
    // form.resetFields();
  };

  const clear = () => {
    setQueryString({ ...queryString, search: '' });
    setQuery('');
  };

  const onFinish = async (values) => {
    try {
      values = removeWhiteSpacesFromString(values);
      let { password, username, income, expense, ...rest } = values;
      username = String(username);
      income = income || 0;
      expense = expense || 0;
      values = { income, expense, ...rest };
      if (modalTitle === 'Create') {
        await create({ username, password, ...values });
      } else {
        if (password && updatedRow.username === username) {
          await update({
            password,
            ...values,
            idOrIds: updatedRow?.id,
          });
        } else if (password && updatedRow.username !== username) {
          await update({
            ...values,
            password,
            username,
            idOrIds: updatedRow?.id,
          });
        } else if (!password && updatedRow.username !== username) {
          await update({
            ...values,
            username,
            idOrIds: updatedRow?.id,
          });
        } else {
          await update({ ...values, idOrIds: updatedRow?.id });
        }
      }
    } catch (error) {
      console.log('error', error, error.message);
      openNotificationWithIcon(
        'error',
        error.message || 'Something went wrong!'
      );
    }
  };

  const onBlockOrUnblock = async (id, action) => {
    try {

      if (window.confirm(`Are you sure you want to ${action || 'block'}${id ? ' id ' + id + ' record' : ' all selected records'}?`)) {
        if (id && action) {
          await block({ idOrIds: id, action });
        } else {
          await block({ idOrIds: selectedRowKeys, action: 'block' });
        }
      }
    } catch (error) {
      openNotificationWithIcon(
        'error',
        error.message || 'Something went wrong!'
      );
    }
  };

  const onRemove = async (id, action) => {
    try {
      if (window.confirm(`Are you sure you want to ${action || 'delete'}${id ? ' id ' + id + ' record' : ' all selected records'}?`)) {
        if (id && action) {
          await remove({ idOrIds: id, action });
        } else {
          await remove({ idOrIds: selectedRowKeys, action: 'delete' });
        }
      }
    } catch (error) {
      console.log('error', error, error.message);
      openNotificationWithIcon(
        'error',
        error.message || 'Something went wrong!'
      );
    }
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    setTableParams({ pagination, filters, sorter });

    // sorting
    if (extra.action !== 'paginate') {
      if (sorter?.order) {
        const sortString = `&sort=${sorter.field}&sort_direction=${sorter.order === 'ascend' ? 'asc' : 'desc'
          }`;
        setQueryString({ ...queryString, sort: sortString });
      } else {
        setQueryString({ ...queryString, sort: '' });
      }
    }

    // filtering
    if (extra.action === 'filter') {
      setQueryString({
        ...queryString,
        filter: makeFiltersQueryStringFromFiltersObjects(filters),
      });
    }
  };

  const onSearch = (value) => {
    value = value.trim();
    setQueryString({
      ...queryString,
      search: encodeURIComponent(value),
    });
    if (value) {
      setPageTitle('Search Results:');
    } else {
      setPageTitle('Users');
    }
    setTableParams({
      ...tableParams,
      pagination: {
        ...tableParams.pagination,
        current: 1,
      },
    });
  };

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      api[type]({
        message: 'Success' || type,
        description: message || 'Success!',
      });
    } else if (type === 'error') {
      api[type]({
        message: 'Error' || type,
        description: message || 'Something went wrong!',
      });
    }
  };

  const createOrUpdate = (title, row) => {
    openModal(title);
    setFields([
      {
        name: ['transporterName'],
        value: row ? row.transporterName : '',
      },
      {
        name: ['driverName'],
        value: row ? row.driverName : '',
      },
      {
        name: ['username'],
        value: row ? row.username : '',
      },
      {
        name: ['password'],
        value: '',
      },
      {
        name: ['income'],
        value: row ? row.income : '',
      },
      {
        name: ['expense'],
        value: row ? row.expense : '',
      },
      {
        name: ['gender'],
        value: row ? row.gender : '',
      },
      {
        name: ['role'],
        value: row ? row.role : '',
      },
      {
        name: ['cityId'],
        value: row ? row.city?.id : '',
      },
    ]);
  };

  const clearAllFiltersAndSorters = () => {
    setTableParams({ ...tableParams, filters: {}, sorter: {} });
    setQueryString({ ...queryString, filter: '', sort: '' });
  };

  return (
    <>
      {contextHolder}
      <TabInfo title='Users' />
      <div className='flex justify-between items-center'>
        <h1 className='text-3xl font-bold tracking-tight text-gray-900'>
          {pageTitle}
        </h1>
        <div className='flex items-center gap-4'>
          {search ? (
            <BackButton

              onClick={() => {
                setPageTitle('Users');
                clear();
              }}
            />
          ) : null}

          <Tooltip placement='left' title={'Clear all filters and sorters'}>
            <Button
              type='primary'
              size='large'

              danger
              onClick={clearAllFiltersAndSorters}
            >
              Clear
            </Button>
          </Tooltip>

          {roleHasAccess && (
            <Button
              className='mr-2'
              size='large'
              type='primary'
              onClick={() => createOrUpdate('Create', null)}
            >
              Create
            </Button>
          )}
          <Tooltip title="Search by transporterName, driverName, username, ID, createdBy ID!">
            <Input.Search
              placeholder='Search by transporterName, driverName, username, ID, createdBy ID!'
              enterButton='Search'
              size='large'
              allowClear
              onSearch={onSearch}
              onChange={(e) => setQuery(e.target.value)}
              value={query}
            />
          </Tooltip>
        </div>
      </div>

      <div className='mt-8'>
        {!getAllIsLoading && getAllData?.isError ? (
          <Alert
            message='Error'
            description={getAllData?.message || 'Something went wrong!'}
            type='error'
            showIcon
          />
        ) : (
          !getAllIsLoading &&
          getAllData?.isSuccess && (
            <>
              {roleHasAccess && (
                <>
                  <div className='mb-4'>
                    <Button
                      type='primary'
                      danger
                      onClick={() => onRemove(undefined, "delete")}
                      disabled={!selectedRowKeys.length > 0}
                      loading={removeIsLoading}
                      size='large'
                    >
                      Delete
                    </Button>
                    <Button
                      type='primary'
                      danger
                      onClick={() => onBlockOrUnblock(undefined, "block")}
                      disabled={!selectedRowKeys.length > 0}
                      loading={removeIsLoading}
                      size='large'
                      className='mx-4'
                    >
                      Block
                    </Button>
                    {selectedRowKeys.length > 0 && (
                      <span>Selected {selectedRowKeys.length} rows</span>
                    )}
                  </div>
                </>
              )}
              <Table
                style={{ width: 'auto' }}
                // sortDirections={['ascend', 'descend', 'ascend']} // to prevent sorter back to default status and if we use sortDirections props on the table then it will affect for all table columns
                rowSelection={rowSelection}
                columns={columns}
                rowKey={(record) => record?.id}
                dataSource={getAllData?.data}
                scroll={{
                  x: 'max-content',
                  y: '90vh',
                  scrollToFirstRowOnChange: true,
                }}
                bordered
                loading={getAllIsLoading || getAllIsFetching}
                pagination={{
                  ...tableParams.pagination,
                  pageSizeOptions: range(
                    constants.PER_PAGE.vehicle,
                    constants.PER_PAGE.vehicle * 11,
                    constants.PER_PAGE.vehicle
                  ),
                  responsive: true,
                  showQuickJumper: true,
                  showSizeChanger: true,
                  showTotal: (total, range) =>
                    `${range[0]}-${range[1]} of ${total} items`,
                  hideOnSinglePage: true,
                  total: getAllData?.total,
                  position: ['topRight'],
                }}
                onChange={onTableChange}
              />
            </>
          )
        )}
      </div>

      <Modal
        title={modalTitle}
        centered
        open={isModelOpen}
        onCancel={closeModal}
        footer={null}
        width={1024}
      >
        <Form
          form={form}
          fields={fields}
          size='large'
          layout='vertical'
          onFinish={onFinish}
          validateMessages={validateMessages}
        >
          <div className='grid grid-cols-12 gap-4 mb-6'>
            <div className='col-span-12 md:col-span-6'>
              <Form.Item
                className='mb-0'
                label='Transporter Name'
                name='transporterName'
              >
                <Input placeholder='Enter transporter name!' />
              </Form.Item>
            </div>
            <div className='col-span-12 md:col-span-6'>
              <Form.Item
                className='mb-0'
                label='Driver Name'
                name='driverName'
                rules={[{ required: true }]}
              >
                <Input placeholder='Enter driver name!' />
              </Form.Item>
            </div>
            <div className='col-span-12 md:col-span-6'>
              <Form.Item
                className='mb-0'
                label='Username'
                name='username'
                rules={[{ required: true }]}
              >
                <InputNumber
                  maxLength={13}
                  placeholder='Enter username!'
                  className='w-full'
                />
              </Form.Item>
            </div>
            <div className='col-span-12 md:col-span-6'>
              <Form.Item
                className='mb-0'
                label='Password'
                name='password'
                rules={[{ required: modalTitle === 'Create' ? true : false }]}
              >
                <Input.Password maxLength={11} placeholder='Enter password!' />
              </Form.Item>
            </div>
            <div className='col-span-12 md:col-span-6'>
              <Form.Item className='mb-0' label='Income' name='income'>
                <InputNumber
                  defaultValue={0}
                  value={0}
                  className='w-full'
                  placeholder='Enter income!'
                />
              </Form.Item>
            </div>
            <div className='col-span-12 md:col-span-6'>
              <Form.Item className='mb-0' label='Expense' name='expense'>
                <InputNumber
                  defaultValue={0}
                  value={0}
                  className='w-full'
                  placeholder='Enter expense!'
                />
              </Form.Item>
            </div>
            <div className='col-span-12 md:col-span-6'>
              <Form.Item
                className='mb-0'
                label='Gender'
                name='gender'
                rules={[{ required: true }]}
              >
                <Select placeholder='Select gender!' options={genders} />
              </Form.Item>
            </div>
            <div className='col-span-12 md:col-span-6'>
              <Form.Item
                className='mb-0'
                label='Role'
                name='role'
                rules={[{ required: true }]}
              >
                <Select placeholder='Select role!' options={roles} />
              </Form.Item>
            </div>
            <div className='col-span-12 md:col-span-6'>
              <Form.Item
                className='mb-0'
                label='City'
                name='cityId'
                rules={[{ required: true }]}
              >
                <Select
                  placeholder='Select city!'
                  options={error ? [] : getModifiedCities(cities, 'label')}
                  showSearch
                  filterOption={filterOption}
                />
              </Form.Item>
            </div>
          </div>
          <Form.Item className='flex justify-end mb-0'>
            <Button htmlType='button' className='mr-2' onClick={closeModal}>
              Cancel
            </Button>
            <Button
              type='primary'
              htmlType='submit'
              loading={createIsLoading || updateIsLoading}
            >
              {modalTitle}
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default Users;
