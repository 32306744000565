import { notification } from 'antd';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../../components/CustomButton';
import CustomInput from '../../components/CustomInput';
import TabInfo from '../../components/TabInfo';
import { useAuthContext } from '../../context';
import { useLoginMutation } from '../../features/apiSlice';

const Signin = () => {
  const { user, login: signin } = useAuthContext();
  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();
  const [login, { data, isLoading }] = useLoginMutation();

  const [admin, setAdmin] = useState({
    username: localStorage.getItem('username') || '',
    password: localStorage.getItem('password')
      ? window.atob(localStorage.getItem('password'))
      : '',
  });
  const { username, password } = admin;
  const [rememberMe, setRememberMe] = useState(
    localStorage.getItem('rememberMe')
      ? Boolean(localStorage.getItem('rememberMe'))
      : false
  );

  useEffect(() => {
    if (Object.keys(user || {}).length > 0) {
      navigate('/dashboard');
    }
  }, [Object.keys(user || {}).length]);

  useEffect(() => {
    if (data?.isSuccess) {
      const {
        user: { driverName, username, role, createdBy },
        token,
      } = data?.data;

      // 1 for admin and 2 for super admin
      if (['1', '2'].includes(role)) {
        signin({
          data: { driverName, username, role, createdBy },
          token,
        });
      } else {
        openNotificationWithIcon(
          'error',
          `You don't have access to login in this dashboard!`
        );
      }
    } else if (data?.isError) {
      openNotificationWithIcon('error', data?.message);
    }
  }, [JSON.stringify(data)]);

  const openNotificationWithIcon = (type, message) => {
    if (type === 'success') {
      api[type]({
        message: 'Success' || type,
        description: message || 'Success!',
      });
    } else if (type === 'error') {
      api[type]({
        message: 'Error' || type,
        description: message || 'Something went wrong!',
      });
    }
  };

  const handleRememberMe = (e) => {
    setRememberMe(e.target.checked);
    if (e.target.checked) {
      const encryptedPassword = window.btoa(password);
      localStorage.setItem('username', username);
      localStorage.setItem('password', encryptedPassword);
      localStorage.setItem('rememberMe', rememberMe);
    } else {
      localStorage.removeItem('username');
      localStorage.removeItem('password');
      localStorage.removeItem('rememberMe');
      setAdmin({ username: '', password: '' });
    }
  };

  const onValueChangeHandler = ({ target: { name, value } }) => {
    setAdmin({ ...admin, [name]: value });
  };

  const submitHandler = async (e) => {
    try {
      e.preventDefault();
      await login(admin);
    } catch (error) {
      console.log('error', error, error.message);
      openNotificationWithIcon({
        type: 'error',
        message: 'Error',
        description: error.message,
      });
    }
  };

  return (
    <>
      {contextHolder} <TabInfo title='Sign In' />
      <form className='space-y-4 md:space-y-6' onSubmit={submitHandler}>
        <CustomInput
          placeholder='Enter  username!'
          label='Username'
          type={'text'}
          name={'username'}
          value={username}
          onChange={onValueChangeHandler}
          minLength={13}
          maxLength={13}
        />
        <CustomInput
          placeholder='••••••••'
          label='Password'
          type={'password'}
          name={'password'}
          value={password}
          onChange={onValueChangeHandler}
          minLength={11}
          maxLength={11}
        />
        <div className='flex items-center justify-between'>
          <div className='flex items-start'>
            <div className='flex items-center h-5'>
              <input
                id='remember'
                aria-describedby='remember'
                type='checkbox'
                className='w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-blue-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-blue-600 dark:ring-offset-gray-800'
                onChange={handleRememberMe}
                defaultChecked={rememberMe}
                disabled={username && password ? false : true}
              />
            </div>
            <div className='ml-3 text-sm'>
              <label
                htmlFor='remember'
                className='text-gray-500 dark:text-gray-300'
              >
                Remember me
              </label>
            </div>
          </div>
        </div>

        <CustomButton
          buttonClasses='w-full'
          title='Sign In'
          isLoading={isLoading}
          disabled={username && password ? false : true}
        />
      </form>
    </>
  );
};

export default Signin;
