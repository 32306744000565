import { Spin } from 'antd';

const SpinnerWithBackdrop = () => {
  return (
    <div className='h-full w-full opacity-50 flex justify-center items-center'>
      <Spin size='large' />
    </div>
  );
};

export default SpinnerWithBackdrop;
