import { Alert } from 'antd';
import CountUp from 'react-countup';
import { Link } from 'react-router-dom';
import SpinnerWithBackdrop from '../../components/SpinnerWithBackdrop';
import TabInfo from '../../components/TabInfo';
import { useGetDashboardAnalyticsQuery } from '../../features/apiSlice';

const Statistics = ({ url, k, v }) => {
  return (
    <div className='text-center font-bold'>
      <p className='text-gray-500 capitalize text-md'>{k}</p>
      {countUpLink({ url, k, v })}
    </div>
  );
};

const CountUpLink = ({ url, v, filter = '', color = 'text-blue-500' }) => {
  return (
    <Link to={`/${url.replace('_', '-')}?${filter}`}>
      <CountUp end={v} className={`${color} text-2xl`} separator=',' />
    </Link>
  );
};

const countUpLink = ({ url, k, v }) => {
  switch (k) {
    case 'active':
      return (
        <CountUpLink
          url={url}
          v={v}
          color='text-[#52c41a]'
          filter='isDeleted=false'
        />
      );
    case 'delete':
      return (
        <CountUpLink
          url={url}
          v={v}
          color='text-[red]'
          filter='isDeleted=true'
        />
      );

    case 'unblock':
      return (
        <CountUpLink
          url={url}
          v={v}
          color='text-[#52c41a]'
          filter='isActive=true'
        />
      );

    case 'block':
      return (
        <CountUpLink
          url={url}
          v={v}
          color='text-[red]'
          filter='isActive=false'
        />
      );
    case 'users':
      return <CountUpLink url={url} v={v} filter='role=0' />;

    case 'admins':
      return <CountUpLink url={url} v={v} filter='role=1' />;

    default:
      return <CountUpLink url={url} v={v} />;
  }
};

const Dashboard = () => {
  const {
    data: getAllData,
    isLoading: getAllIsLoading,
    isFetching: getAllIsFetching,
  } = useGetDashboardAnalyticsQuery();

  return (
    <>
      <TabInfo title='Dashboard' />
      <div className='flex justify-between items-center'>
        <h1 className='text-3xl font-bold tracking-tight text-gray-900'>
          Dashboard
        </h1>
      </div>

      <div className='mt-8'>
        {getAllIsLoading && getAllIsFetching && (
          <div className='h-screen'>
            <SpinnerWithBackdrop />
          </div>
        )}
        {!getAllIsLoading && getAllData?.isError ? (
          <Alert
            message='Error'
            description={getAllData?.message || 'Something went wrong!'}
            type='error'
            showIcon
          />
        ) : (
          !getAllIsLoading &&
          getAllData?.isSuccess && (
            <div className='grid gap-6 grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4'>
              {Object.entries(getAllData?.data).map(([key, value], index) => {
                return (
                  <div
                    className={`rounded-xl shadow-2xl overflow-hidden bg-white p-4`}
                  >
                    <p className='mb-8 text-center text-lg font-bold text-gray-500 capitalize'>
                      {key.split('_').join(' ')}
                    </p>
                    {Object.entries(value).map(
                      ([k, v]) =>
                        k === 'total' && <Statistics url={key} k={k} v={v} />
                    )}
                    <div className='grid gap-2 grid-cols-1 md:grid-cols-2 mt-8'>
                      {Object.entries(value).map(
                        ([k, v]) =>
                          k !== 'total' && <Statistics url={key} k={k} v={v} />
                      )}
                    </div>
                  </div>
                );
              })}
            </div>
          )
        )}
      </div>
    </>
  );
};

export default Dashboard;
