import { FloatButton, Layout, Menu, notification, theme } from 'antd';
import React, { useMemo } from 'react';
import { AiOutlineLogout } from 'react-icons/ai';
import { CgDetailsMore } from 'react-icons/cg';
import { FaArrowUp } from 'react-icons/fa';
import { Link, useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context';
import { useWindowSize } from '../../hooks';
import { routes } from '../../routes';

const { Content, Sider } = Layout;

const CustomLayout = ({ children }) => {
  const { width } = useWindowSize();
  const {
    token: { colorBgContainer },
  } = theme.useToken();

  const { user, logout } = useAuthContext();

  const navigate = useNavigate();
  const [api, contextHolder] = notification.useNotification();

  const openNotificationWithIcon = ({ type, message, description }) => {
    api[type]({ message: message || type, description });
  };

  const items = useMemo(
    () =>
      routes
        // .filter((route) => typeof route.label !== 'string')
        .map(({ label, icon }, index) => ({
          key: String(index + 1),
          icon,
          label,
        })),
    []
  );

  return (
    <>
      {contextHolder}
      <Layout hasSider>
        <Sider
          style={
            width > 768
              ? {
                  overflow: 'auto',
                  height: '100vh',
                  position: 'fixed',
                  left: 0,
                  top: 0,
                  bottom: 0,
                }
              : {}
          }
          breakpoint='md'
          collapsedWidth='0'
          onBreakpoint={(broken) => {
            // console.log(broken);
          }}
          onCollapse={(collapsed, type) => {
            // console.log(collapsed, type);
          }}
        >
          <div className='flex justify-center items-center h-20'>
            <h1 className='text-white text-xl font-black'>
              <Link to='/dashboard'>SWIFT</Link>
            </h1>
          </div>
          <div className='flex flex-col justify-between h-[85%]'>
            <Menu
              theme='dark'
              mode='inline'
              items={items}
              className='sidebar-menu'
            />
          </div>
        </Sider>
        <Layout
          className='site-layout'
          style={{ marginLeft: 200, height: '100vh' }}
        >
          <Content style={{ margin: '24px 16px', overflow: 'initial' }}>
            <div style={{ padding: 24, background: colorBgContainer }}>
              {children}
            </div>
          </Content>
        </Layout>
        <FloatButton.BackTop
          visibilityHeight={10}
          type='primary'
          style={{ bottom: '3%' }}
          icon={<FaArrowUp />}
        />
      </Layout>
      <FloatButton.Group
        trigger='hover'
        style={{ left: '5%', bottom: '3%' }}
        type='primary'
        icon={<CgDetailsMore />}
      >
        <FloatButton
          tooltip={<div>Logout</div>}
          icon={<AiOutlineLogout onClick={logout} />}
          className='hover:bg-white'
        />
      </FloatButton.Group>
    </>
  );
};

export default CustomLayout;
