import React from 'react';
import { Link } from 'react-router-dom';

const CreatorLink = ({ creator }) => {
  if (creator !== null) {
    const { id, username, driverName, isDeleted } = creator;
    return (
      <Link to={`/users?id=${id}`} className='text-[#1677ff]'>
        {isDeleted ? (
          <span className='text-[red]'>{`${username} / ${driverName}`}</span>
        ) : (
          <span>{`${username} / ${driverName}`}</span>
        )}
      </Link>
    );
  } else {
    return "---";
  }
};

export default CreatorLink;
