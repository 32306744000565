import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import { api } from '../features/apiSlice';
import { cityReducer } from '../features/citySlice';
import { expenseTypeReducer } from '../features/expenseTypeSlice';
import { vehicleColorReducer } from '../features/vehicleColorSlice';
import { vehicleMakerReducer } from '../features/vehicleMakerSlice';
import { vehicleModelReducer } from '../features/vehicleModelSlice';

export const store = configureStore({
  reducer: {
    // Add the generated reducer as a specific top-level slice
    [api.reducerPath]: api.reducer,
    city: cityReducer,
    expenseType: expenseTypeReducer,
    vehicleMaker: vehicleMakerReducer,
    vehicleModel: vehicleModelReducer,
    vehicleColor: vehicleColorReducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware),
});

setupListeners(store.dispatch);
