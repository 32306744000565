import { AiOutlineBarChart } from 'react-icons/ai';
import { HiOutlineUsers } from 'react-icons/hi';
import { NavLink } from 'react-router-dom';
import { ProtectedRoute } from './components/ProtectedRoute';

import Cities from './screens/Cities';
import Dashboard from './screens/Dashboard';
import Events from './screens/Events';
import Expenses from './screens/Expenses';
import ExpenseTypes from './screens/ExpenseTypes';
import Incomes from './screens/Incomes';
import MessageTemplates from './screens/MessageTemplates';
import Passengers from './screens/Passengers';
import Users from './screens/Users';
import VehicleColors from './screens/VehicleColors';
import VehicleMakers from './screens/VehicleMakers';
import VehicleModels from './screens/VehicleModels';
import Vehicles from './screens/Vehicles';

const addActiveClass = (isActive) =>
  isActive ? 'active' : 'flex items-center';

export const routes = [
  {
    label: (
      <NavLink
        to='/dashboard'
        className={({ isActive }) => addActiveClass(isActive)}
      >
        <AiOutlineBarChart size={20} className='ml-3 mr-2' />
        Dashboard
      </NavLink>
    ),
    route: '/dashboard',
    component: <ProtectedRoute Component={Dashboard} />,
  },
  {
    label: (
      <NavLink
        to='/users'
        className={({ isActive }) => addActiveClass(isActive)}
      >
        <HiOutlineUsers size={20} className='ml-3 mr-2' />
        Users
      </NavLink>
    ),
    route: '/users',
    component: <ProtectedRoute Component={Users} />,
  },
  {
    label: (
      <NavLink
        to='/cities'
        className={({ isActive }) => addActiveClass(isActive)}
      >
        <HiOutlineUsers size={20} className='ml-3 mr-2' />
        Cities
      </NavLink>
    ),
    route: '/cities',
    component: <ProtectedRoute Component={Cities} />,
  },
  {
    label: (
      <NavLink
        to='/passengers'
        className={({ isActive }) => addActiveClass(isActive)}
      >
        <HiOutlineUsers size={20} className='ml-3 mr-2' />
        Passengers
      </NavLink>
    ),
    route: '/passengers',
    component: <ProtectedRoute Component={Passengers} />,
  },
  {
    label: (
      <NavLink
        to='/events'
        className={({ isActive }) => addActiveClass(isActive)}
      >
        <HiOutlineUsers size={20} className='ml-3 mr-2' />
        Events
      </NavLink>
    ),
    route: '/events',
    component: <ProtectedRoute Component={Events} />,
  },
  {
    label: (
      <NavLink
        to='/incomes'
        className={({ isActive }) => addActiveClass(isActive)}
      >
        <HiOutlineUsers size={20} className='ml-3 mr-2' />
        Incomes
      </NavLink>
    ),
    route: '/incomes',
    component: <ProtectedRoute Component={Incomes} />,
  },
  {
    label: (
      <NavLink
        to='/vehicles'
        className={({ isActive }) => addActiveClass(isActive)}
      >
        <HiOutlineUsers size={20} className='ml-3 mr-2' />
        Vehicles
      </NavLink>
    ),
    route: '/vehicles',
    component: <ProtectedRoute Component={Vehicles} />,
  },

  {
    label: (
      <NavLink
        to='/expenses'
        className={({ isActive }) => addActiveClass(isActive)}
      >
        <HiOutlineUsers size={20} className='ml-3 mr-2' />
        Expenses
      </NavLink>
    ),
    route: '/expenses',
    component: <ProtectedRoute Component={Expenses} />,
  },
  {
    label: (
      <NavLink
        to='/message-templates'
        className={({ isActive }) => addActiveClass(isActive)}
      >
        <HiOutlineUsers size={20} className='ml-3 mr-2' />
        Message Templates
      </NavLink>
    ),
    route: '/message-templates',
    component: <ProtectedRoute Component={MessageTemplates} />,
  },
  {
    label: (
      <NavLink
        to='/expense-types'
        className={({ isActive }) => addActiveClass(isActive)}
      >
        <HiOutlineUsers size={20} className='ml-3 mr-2' />
        Expense Types
      </NavLink>
    ),
    route: '/expense-types',
    component: <ProtectedRoute Component={ExpenseTypes} />,
  },

  {
    label: (
      <NavLink
        to='/vehicle-colors'
        className={({ isActive }) => addActiveClass(isActive)}
      >
        <HiOutlineUsers size={20} className='ml-3 mr-2' />
        Vehicle Colors
      </NavLink>
    ),
    route: '/vehicle-colors',
    component: <ProtectedRoute Component={VehicleColors} />,
  },
  {
    label: (
      <NavLink
        to='/vehicle-makers'
        className={({ isActive }) => addActiveClass(isActive)}
      >
        <HiOutlineUsers size={20} className='ml-3 mr-2' />
        Vehicle Makers
      </NavLink>
    ),
    route: '/vehicle-makers',
    component: <ProtectedRoute Component={VehicleMakers} />,
  },
  {
    label: (
      <NavLink
        to='/vehicle-models'
        className={({ isActive }) => addActiveClass(isActive)}
      >
        <HiOutlineUsers size={20} className='ml-3 mr-2' />
        Vehicle Models
      </NavLink>
    ),
    route: '/vehicle-models',
    component: <ProtectedRoute Component={VehicleModels} />,
  },

];
