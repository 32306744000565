import { Navigate, Route, Routes } from 'react-router-dom';
import FormLayout from './components/FormLayout';
import {
  useGetCitiesWithoutPaginationQuery,
  useGetExpenseTypesWithoutPaginationQuery,
  useGetVehicleColorsWithoutPaginationQuery,
  useGetVehicleMakersWithoutPaginationQuery,
  useGetVehicleModelsWithoutPaginationQuery,
} from './features/apiSlice';
import { routes } from './routes';
import PageNotFound from './screens/PageNotFound';
import Signin from './screens/Signin';

const App = () => {
  useGetCitiesWithoutPaginationQuery(
    `?pagination=false&fields=id,name,state,isDeleted`
  );
  useGetExpenseTypesWithoutPaginationQuery(
    `?pagination=false&fields=id,name,isDeleted&sort=name`
  );
  useGetVehicleMakersWithoutPaginationQuery(
    `?pagination=false&fields=id,name,vehicleType,isDeleted&sort=name`
  );
  useGetVehicleModelsWithoutPaginationQuery(
    `?pagination=false&fields=id,name,isDeleted,makerId&sort=name`
  );
  useGetVehicleColorsWithoutPaginationQuery(
    `?pagination=false&fields=id,name,code,isDeleted&sort=name`
  );
  return (
    <Routes>
      <Route path='/' element={<Navigate replace to='/dashboard' />} />

      {routes?.length > 0 &&
        routes.map((route, index) => {
          return (
            <Route path={route.route} element={route.component} key={index} />
          );
        })}

      <Route
        path='/sign-in'
        element={
          <FormLayout>
            <Signin />
          </FormLayout>
        }
      />

      {/* <Route path='*' element={<Navigate to='/dashboard' />} /> */}
      <Route path='*' element={<PageNotFound />} />
    </Routes>
  );
};

export default App;
