import { Helmet } from 'react-helmet';

const TabInfo = ({ title }) => {
  return (
    <Helmet>
      <title>Swift ({title})</title>
    </Helmet>
  );
};

export default TabInfo;
