import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { constants } from '../constants';
import { makeUrlFromIdOrIds } from '../utilities';

// credentials: 'include', // This allows server to set cookies

export const api = createApi({
  reducerPath: 'api',
  baseQuery: fetchBaseQuery({
    baseUrl: constants.SERVER_BASE_URL,
    credentials: 'include',
    prepareHeaders: (headers, globalSettings) => {
      const { getState, extra, endpoint, type, forced } = globalSettings;
      // const token = getState().auth.token;
      const token = JSON.parse(localStorage.getItem('user'))?.token;
      if (token) {
        headers.set('Authorization', `Bearer ${token}`);
      }
      return headers;
    },
    validateStatus: (response, body) => {
      // console.log({ response, body });
      if (response.status === 401) {
        localStorage.setItem('user', JSON.stringify({}));
        // location.replace('/sign-in');
        // console.log('unAuthorized', response.status);
        // redirect('/sign-in');
        return true;
      } else {
        return true;
      }
    },
  }),
  tagTypes: [
    'User',
    'City',
    'Event',
    'ExpenseType',
    'Income',
    'Expense',
    'MessageTemplate',
    'Passenger',
    'VehicleColor',
    'VehicleMaker',
    'VehicleModel',
    'Vehicle',
  ],
  endpoints: (builder) => ({
    // dashboard related mutations and queries
    getDashboardAnalytics: builder.query({
      query: () => `/dashboard/web`,
      providesTags: [
        'User',
        'City',
        'Event',
        'ExpenseType',
        'Income',
        'Expense',
        'MessageTemplate',
        'Passenger',
        'VehicleColor',
        'VehicleMaker',
        'VehicleModel',
        'Vehicle',
      ],
    }),
    // user related mutations and queries
    register: builder.mutation({
      query: (data) => {
        return {
          url: `/auth/register`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['User'],
    }),
    login: builder.mutation({
      query: (data) => {
        return {
          url: `/auth/login`,
          method: 'POST',
          body: data,
        };
      },
    }),
    getUser: builder.query({
      query: (id) => `/auth/${id}`,
      providesTags: ['User'],
    }),
    getUsers: builder.query({
      query: (params) => `/auth${params}`,
      providesTags: ['User'],
    }),
    updateUserOrUsers: builder.mutation({
      query: (data) => {
        const { idOrIds, ...rest } = data;
        return {
          url: makeUrlFromIdOrIds('/auth', idOrIds),
          method: 'PATCH',
          body: rest,
        };
      },
      invalidatesTags: ['User'],
    }),
    blockOrUnBlockUser: builder.mutation({
      query: (data) => {
        const { idOrIds, action } = data;
        return {
          url: makeUrlFromIdOrIds('/auth/block', idOrIds, action),
          method: 'PATCH',
        };
      },
      invalidatesTags: ['User'],
    }),
    deleteUserOrUsers: builder.mutation({
      query: (data) => {
        const { idOrIds, action } = data;
        return {
          url: makeUrlFromIdOrIds('/auth', idOrIds, action),
          method: 'DELETE',
        };
      },
      invalidatesTags: ['User'],
    }),
    // city related mutations and queries
    createCity: builder.mutation({
      query: (data) => {
        return {
          url: `/cities`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['City'],
    }),
    getCity: builder.query({
      query: (id) => `/cities/${id}`,
      providesTags: ['City'],
    }),
    getCities: builder.query({
      query: (params) => `/cities${params}`,
      providesTags: ['City'],
    }),
    getCitiesWithoutPagination: builder.query({
      query: (params) => `/cities${params}`,
      providesTags: ['City'],
    }),
    updateCityOrCities: builder.mutation({
      query: (data) => {
        const { idOrIds, ...rest } = data;
        return {
          url: makeUrlFromIdOrIds('/cities', idOrIds),
          method: 'PATCH',
          body: rest,
        };
      },
      invalidatesTags: ['City'],
    }),
    deleteCityOrCities: builder.mutation({
      query: (data) => {
        const { idOrIds, action } = data;
        return {
          url: makeUrlFromIdOrIds('/cities', idOrIds, action),
          method: 'DELETE',
        };
      },
      invalidatesTags: ['City'],
    }),
    // event related mutations and queries
    createEvent: builder.mutation({
      query: (data) => {
        return {
          url: `/events`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['Event'],
    }),
    getEvent: builder.query({
      query: (id) => `/events/${id}`,
      providesTags: ['Event'],
    }),
    getEvents: builder.query({
      query: (params) => `/events${params}`,
      providesTags: ['Event'],
    }),
    updateEventOrEvents: builder.mutation({
      query: (data) => {
        const { idOrIds, ...rest } = data;
        return {
          url: makeUrlFromIdOrIds('/events', idOrIds),
          method: 'PATCH',
          body: rest,
        };
      },
      invalidatesTags: ['Event'],
    }),
    deleteEventOrEvents: builder.mutation({
      query: (data) => {
        const { idOrIds, action } = data;
        return {
          url: makeUrlFromIdOrIds('/events', idOrIds, action),
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Event'],
    }),
    // expenseType related mutations and queries
    createExpenseType: builder.mutation({
      query: (data) => {
        return {
          url: `/expense-types`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['ExpenseType'],
    }),
    getExpenseType: builder.query({
      query: (id) => `/expense-types/${id}`,
      providesTags: ['ExpenseType'],
    }),
    getExpenseTypes: builder.query({
      query: (params) => `/expense-types${params}`,
      providesTags: ['ExpenseType'],
    }),
    getExpenseTypesWithoutPagination: builder.query({
      query: (params) => `/expense-types${params}`,
      providesTags: ['ExpenseType'],
    }),
    updateExpenseTypeOrExpenseTypes: builder.mutation({
      query: (data) => {
        const { idOrIds, ...rest } = data;
        return {
          url: makeUrlFromIdOrIds('/expense-types', idOrIds),
          method: 'PATCH',
          body: rest,
        };
      },
      invalidatesTags: ['ExpenseType'],
    }),
    deleteExpenseTypeOrExpenseTypes: builder.mutation({
      query: (data) => {
        const { idOrIds, action } = data;
        return {
          url: makeUrlFromIdOrIds('/expense-types', idOrIds, action),
          method: 'DELETE',
        };
      },
      invalidatesTags: ['ExpenseType'],
    }),
    // income related mutations and queries
    createIncome: builder.mutation({
      query: (data) => {
        return {
          url: `/incomes`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['Income'],
    }),
    getIncome: builder.query({
      query: (id) => `/incomes/${id}`,
      providesTags: ['Income'],
    }),
    getIncomes: builder.query({
      query: (params) => `/incomes${params}`,
      providesTags: ['Income'],
    }),
    updateIncomeOrIncomes: builder.mutation({
      query: (data) => {
        const { idOrIds, ...rest } = data;
        return {
          url: makeUrlFromIdOrIds('/incomes', idOrIds),
          method: 'PATCH',
          body: rest,
        };
      },
      invalidatesTags: ['Income'],
    }),
    deleteIncomeOrIncomes: builder.mutation({
      query: (data) => {
        const { idOrIds, action } = data;
        return {
          url: makeUrlFromIdOrIds('/incomes', idOrIds, action),
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Income'],
    }),
    // expense related mutations and queries
    createExpense: builder.mutation({
      query: (data) => {
        return {
          url: `/expenses`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['Expense'],
    }),
    getExpense: builder.query({
      query: (id) => `/expenses/${id}`,
      providesTags: ['Expense'],
    }),
    getExpenses: builder.query({
      query: (params) => `/expenses${params}`,
      providesTags: ['Expense'],
    }),
    updateExpenseOrExpenses: builder.mutation({
      query: (data) => {
        const { idOrIds, ...rest } = data;
        return {
          url: makeUrlFromIdOrIds('/expenses', idOrIds),
          method: 'PATCH',
          body: rest,
        };
      },
      invalidatesTags: ['Expense'],
    }),
    deleteExpenseOrExpenses: builder.mutation({
      query: (data) => {
        const { idOrIds, action } = data;
        return {
          url: makeUrlFromIdOrIds('/expenses', idOrIds, action),
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Expense'],
    }),
    // messageTemplate related mutations and queries
    createMessageTemplate: builder.mutation({
      query: (data) => {
        return {
          url: `/message-templates`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['MessageTemplate'],
    }),
    getMessageTemplate: builder.query({
      query: (id) => `/message-templates/${id}`,
      providesTags: ['MessageTemplate'],
    }),
    getMessageTemplates: builder.query({
      query: (params) => `/message-templates${params}`,
      providesTags: ['MessageTemplate'],
    }),
    updateMessageTemplateOrMessageTemplates: builder.mutation({
      query: (data) => {
        const { idOrIds, ...rest } = data;
        return {
          url: makeUrlFromIdOrIds('/message-templates', idOrIds),
          method: 'PATCH',
          body: rest,
        };
      },
      invalidatesTags: ['MessageTemplate'],
    }),
    deleteMessageTemplateOrMessageTemplates: builder.mutation({
      query: (data) => {
        const { idOrIds, action } = data;
        return {
          url: makeUrlFromIdOrIds('/message-templates', idOrIds, action),
          method: 'DELETE',
        };
      },
      invalidatesTags: ['MessageTemplate'],
    }),
    // passenger related mutations and queries
    createPassenger: builder.mutation({
      query: (data) => {
        return {
          url: `/passengers`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['Passenger'],
    }),
    getPassenger: builder.query({
      query: (id) => `/passengers/${id}`,
      providesTags: ['Passenger'],
    }),
    getPassengers: builder.query({
      query: (params) => `/passengers${params}`,
      providesTags: ['Passenger'],
    }),
    updatePassengerOrPassengers: builder.mutation({
      query: (data) => {
        const { idOrIds, ...rest } = data;
        return {
          url: makeUrlFromIdOrIds('/passengers', idOrIds),
          method: 'PATCH',
          body: rest,
        };
      },
      invalidatesTags: ['Passenger'],
    }),
    deletePassengerOrPassengers: builder.mutation({
      query: (data) => {
        const { idOrIds, action } = data;
        return {
          url: makeUrlFromIdOrIds('/passengers', idOrIds, action),
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Passenger'],
    }),
    // vehicleColor related mutations and queries
    createVehicleColor: builder.mutation({
      query: (data) => {
        return {
          url: `/vehicle-colors`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['VehicleColor'],
    }),
    getVehicleColor: builder.query({
      query: (id) => `/vehicle-colors/${id}`,
      providesTags: ['VehicleColor'],
    }),
    getVehicleColors: builder.query({
      query: (params) => `/vehicle-colors${params}`,
      providesTags: ['VehicleColor'],
    }),
    getVehicleColorsWithoutPagination: builder.query({
      query: (params) => `/vehicle-colors${params}`,
      providesTags: ['VehicleColor'],
    }),
    updateVehicleColorOrVehicleColors: builder.mutation({
      query: (data) => {
        const { idOrIds, ...rest } = data;
        return {
          url: makeUrlFromIdOrIds('/vehicle-colors', idOrIds),
          method: 'PATCH',
          body: rest,
        };
      },
      invalidatesTags: ['VehicleColor'],
    }),
    deleteVehicleColorOrVehicleColors: builder.mutation({
      query: (data) => {
        const { idOrIds, action } = data;
        return {
          url: makeUrlFromIdOrIds('/vehicle-colors', idOrIds, action),
          method: 'DELETE',
        };
      },
      invalidatesTags: ['VehicleColor'],
    }),
    // vehicleMaker related mutations and queries
    createVehicleMaker: builder.mutation({
      query: (data) => {
        return {
          url: `/vehicle-makers`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['VehicleMaker'],
    }),
    getVehicleMaker: builder.query({
      query: (id) => `/vehicle-makers/${id}`,
      providesTags: ['VehicleMaker'],
    }),
    getVehicleMakers: builder.query({
      query: (params) => `/vehicle-makers${params}`,
      providesTags: ['VehicleMaker'],
    }),
    getVehicleMakersWithoutPagination: builder.query({
      query: (params) => `/vehicle-makers${params}`,
      providesTags: ['VehicleMaker'],
    }),
    updateVehicleMakerOrVehicleMakers: builder.mutation({
      query: (data) => {
        const { idOrIds, ...rest } = data;
        return {
          url: makeUrlFromIdOrIds('/vehicle-makers', idOrIds),
          method: 'PATCH',
          body: rest,
        };
      },
      invalidatesTags: ['VehicleMaker'],
    }),
    deleteVehicleMakerOrVehicleMakers: builder.mutation({
      query: (data) => {
        const { idOrIds, action } = data;
        return {
          url: makeUrlFromIdOrIds('/vehicle-makers', idOrIds, action),
          method: 'DELETE',
        };
      },
      invalidatesTags: ['VehicleMaker'],
    }),
    // vehicleModel related mutations and queries
    createVehicleModel: builder.mutation({
      query: (data) => {
        return {
          url: `/vehicle-models`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['VehicleModel'],
    }),
    getVehicleModel: builder.query({
      query: (id) => `/vehicle-models/${id}`,
      providesTags: ['VehicleModel'],
    }),
    getVehicleModels: builder.query({
      query: (params) => `/vehicle-models${params}`,
      providesTags: ['VehicleModel'],
    }),
    getVehicleModelsWithoutPagination: builder.query({
      query: (params) => `/vehicle-models${params}`,
      providesTags: ['VehicleModel'],
    }),
    updateVehicleModelOrVehicleModels: builder.mutation({
      query: (data) => {
        const { idOrIds, ...rest } = data;
        return {
          url: makeUrlFromIdOrIds('/vehicle-models', idOrIds),
          method: 'PATCH',
          body: rest,
        };
      },
      invalidatesTags: ['VehicleModel'],
    }),
    deleteVehicleModelOrVehicleModels: builder.mutation({
      query: (data) => {
        const { idOrIds, action } = data;
        return {
          url: makeUrlFromIdOrIds('/vehicle-models', idOrIds, action),
          method: 'DELETE',
        };
      },
      invalidatesTags: ['VehicleModel'],
    }),
    // vehicle related mutations and queries
    createVehicle: builder.mutation({
      query: (data) => {
        return {
          url: `/vehicles`,
          method: 'POST',
          body: data,
        };
      },
      invalidatesTags: ['Vehicle'],
    }),
    getVehicle: builder.query({
      query: (id) => `/vehicles/${id}`,
      providesTags: ['Vehicle'],
    }),
    getVehicles: builder.query({
      query: (params) => `/vehicles${params}`,
      providesTags: ['Vehicle'],
    }),
    updateVehicleOrVehicles: builder.mutation({
      query: (data) => {
        const { idOrIds, ...rest } = data;
        return {
          url: makeUrlFromIdOrIds('/vehicles', idOrIds),
          method: 'PATCH',
          body: rest,
        };
      },
      invalidatesTags: ['Vehicle'],
    }),
    deleteVehicleOrVehicles: builder.mutation({
      query: (data) => {
        const { idOrIds, action } = data;
        return {
          url: makeUrlFromIdOrIds('/vehicles', idOrIds, action),
          method: 'DELETE',
        };
      },
      invalidatesTags: ['Vehicle'],
    }),
  }),
});

export const {
  // dashboard related mutations and queries
  useGetDashboardAnalyticsQuery,
  useLazyGetDashboardAnalyticsQuery,
  // user related mutations and queries
  useRegisterMutation,
  useLoginMutation,
  useGetUserQuery,
  useLazyGetUserQuery,
  useGetUsersQuery,
  useLazyGetUsersQuery,
  useUpdateUserOrUsersMutation,
  useBlockOrUnBlockUserMutation,
  useDeleteUserOrUsersMutation,
  // city related mutations and queries
  useCreateCityMutation,
  useGetCityQuery,
  useLazyGetCityQuery,
  useGetCitiesQuery,
  useLazyGetCitiesQuery,
  useGetCitiesWithoutPaginationQuery,
  useUpdateCityOrCitiesMutation,
  useDeleteCityOrCitiesMutation,
  // event related mutations and queries
  useCreateEventMutation,
  useGetEventQuery,
  useLazyGetEventQuery,
  useGetEventsQuery,
  useLazyGetEventsQuery,
  useUpdateEventOrEventsMutation,
  useDeleteEventOrEventsMutation,
  // expenseType related mutations and queries
  useCreateExpenseTypeMutation,
  useGetExpenseTypeQuery,
  useLazyGetExpenseTypeQuery,
  useGetExpenseTypesQuery,
  useLazyGetExpenseTypesQuery,
  useGetExpenseTypesWithoutPaginationQuery,
  useUpdateExpenseTypeOrExpenseTypesMutation,
  useDeleteExpenseTypeOrExpenseTypesMutation,
  // income related mutations and queries
  useCreateIncomeMutation,
  useGetIncomeQuery,
  useLazyGetIncomeQuery,
  useGetIncomesQuery,
  useLazyGetIncomesQuery,
  useUpdateIncomeOrIncomesMutation,
  useDeleteIncomeOrIncomesMutation,
  // expense related mutations and queries
  useCreateExpenseMutation,
  useGetExpenseQuery,
  useLazyGetExpenseQuery,
  useGetExpensesQuery,
  useLazyGetExpensesQuery,
  useUpdateExpenseOrExpensesMutation,
  useDeleteExpenseOrExpensesMutation,
  // messageTemplate related mutations and queries
  useCreateMessageTemplateMutation,
  useGetMessageTemplateQuery,
  useLazyGetMessageTemplateQuery,
  useGetMessageTemplatesQuery,
  useLazyGetMessageTemplatesQuery,
  useUpdateMessageTemplateOrMessageTemplatesMutation,
  useDeleteMessageTemplateOrMessageTemplatesMutation,
  // passenger related mutations and queries
  useCreatePassengerMutation,
  useGetPassengerQuery,
  useLazyGetPassengerQuery,
  useGetPassengersQuery,
  useLazyGetPassengersQuery,
  useUpdatePassengerOrPassengersMutation,
  useDeletePassengerOrPassengersMutation,
  // vehicleColor related mutations and queries
  useCreateVehicleColorMutation,
  useGetVehicleColorQuery,
  useLazyGetVehicleColorQuery,
  useGetVehicleColorsQuery,
  useLazyGetVehicleColorsQuery,
  useGetVehicleColorsWithoutPaginationQuery,
  useUpdateVehicleColorOrVehicleColorsMutation,
  useDeleteVehicleColorOrVehicleColorsMutation,
  // vehicleMaker related mutations and queries
  useCreateVehicleMakerMutation,
  useGetVehicleMakerQuery,
  useLazyGetVehicleMakerQuery,
  useGetVehicleMakersQuery,
  useLazyGetVehicleMakersQuery,
  useGetVehicleMakersWithoutPaginationQuery,
  useUpdateVehicleMakerOrVehicleMakersMutation,
  useDeleteVehicleMakerOrVehicleMakersMutation,
  // vehicleModel related mutations and queries
  useCreateVehicleModelMutation,
  useGetVehicleModelQuery,
  useLazyGetVehicleModelQuery,
  useGetVehicleModelsQuery,
  useLazyGetVehicleModelsQuery,
  useGetVehicleModelsWithoutPaginationQuery,
  useUpdateVehicleModelOrVehicleModelsMutation,
  useDeleteVehicleModelOrVehicleModelsMutation,
  // vehicle related mutations and queries
  useCreateVehicleMutation,
  useGetVehicleQuery,
  useLazyGetVehicleQuery,
  useGetVehiclesQuery,
  useLazyGetVehiclesQuery,
  useUpdateVehicleOrVehiclesMutation,
  useDeleteVehicleOrVehiclesMutation,
} = api;
