import { createContext, useContext } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLocalStorage } from '../hooks';

const Context = createContext();

export const useAuthContext = () => {
  return useContext(Context);
};

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useLocalStorage('user', {});
  const navigate = useNavigate();

  const login = (user) => {
    setUser(user);
  };

  const logout = () => {
    setUser({});
    navigate('/sign-in');
  };

  return (
    <Context.Provider
      value={{
        login,
        logout,
        user,
      }}
    >
      {children}
    </Context.Provider>
  );
};
