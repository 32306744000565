import { createSlice } from '@reduxjs/toolkit';
import { api } from './apiSlice';

const initialState = {
  isLoading: false,
  vehicleMakers: [],
  error: '',
};

const vehicleMakerSlice = createSlice({
  name: 'vehicleMaker',
  initialState,
  reducers: {
    /* some reducers here maybe? */
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.getVehicleMakersWithoutPagination.matchPending,
      (state, action) => {
        state.isLoading = true;
      }
    );
    builder.addMatcher(
      api.endpoints.getVehicleMakersWithoutPagination.matchFulfilled,
      (state, action) => {
        const { type, payload, meta } = action;
        state.isLoading = false;
        if (payload.isSuccess) {
          state.vehicleMakers = payload.data;
        } else {
          state.error = payload.message;
        }
      }
    );
    builder.addMatcher(
      api.endpoints.getVehicleMakersWithoutPagination.matchRejected,
      (state, { type, payload, meta }) => {
        state.isLoading = false;
        state.error = payload;
      }
    );
  },
});

export const vehicleMakerReducer = vehicleMakerSlice.reducer;
export const vehicleMakerActions = vehicleMakerSlice.actions;
