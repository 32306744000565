import { HiArrowLeft } from 'react-icons/hi';
import { useNavigate } from 'react-router-dom';
import CustomButton from '../CustomButton';

const BackButton = ({ title = 'Back', buttonClasses = '', path = '', onClick, ...rest }) => {
  const navigate = useNavigate();
  return (
    <CustomButton
      title={title}
      buttonClasses={`bg-black hover:bg-slate-700 ${buttonClasses}`}
      style={{ backgroundColor: '#000' }}
      onClick={() => (path ? navigate(path) : onClick())}
      {...rest}
    >
      <HiArrowLeft size={18} className='mr-2' />
    </CustomButton>
  );
};

export default BackButton;
