import { createSlice } from '@reduxjs/toolkit';
import { api } from './apiSlice';

const initialState = {
  isLoading: false,
  vehicleModels: [],
  error: '',
};

const vehicleModelSlice = createSlice({
  name: 'vehicleModel',
  initialState,
  reducers: {
    /* some reducers here maybe? */
  },
  extraReducers: (builder) => {
    builder.addMatcher(
      api.endpoints.getVehicleModelsWithoutPagination.matchPending,
      (state, action) => {
        state.isLoading = true;
      }
    );
    builder.addMatcher(
      api.endpoints.getVehicleModelsWithoutPagination.matchFulfilled,
      (state, action) => {
        const { type, payload, meta } = action;
        state.isLoading = false;
        if (payload.isSuccess) {
          state.vehicleModels = payload.data;
        } else {
          state.error = payload.message;
        }
      }
    );
    builder.addMatcher(
      api.endpoints.getVehicleModelsWithoutPagination.matchRejected,
      (state, { type, payload, meta }) => {
        state.isLoading = false;
        state.error = payload;
      }
    );
  },
});

export const vehicleModelReducer = vehicleModelSlice.reducer;
export const vehicleModelActions = vehicleModelSlice.actions;
