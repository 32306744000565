// https://medium.com/@dennisivy/creating-protected-routes-with-react-router-v6-2c4bbaf7bc1c

import { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useAuthContext } from '../../context';
import CustomLayout from '../CustomLayout';

export const ProtectedRoute = ({ Component }) => {
  const { user } = useAuthContext();
  const navigate = useNavigate();

  useEffect(() => {
    if (!Object.keys(user || {}).length > 0) {
      navigate('/sign-in');
    }
  }, []);

  return (
    <CustomLayout>
      <Component />
    </CustomLayout>
  );
};
