import { Form, Input, Select } from 'antd';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { getStateLabelById, getVehicleTypeLabelById } from '../constants';

const parseJwt = (token) => {
  try {
    return JSON.parse(atob(token.split('.')[1]));
  } catch (e) {
    return null;
  }
};

export const AuthVerify = (props) => {
  let location = useLocation();
  useEffect(() => {
    const user = JSON.parse(localStorage.getItem('user'));
    if (Object.keys(user || {})?.length > 0) {
      const decodedJwt = parseJwt(user?.jwt);
      if (decodedJwt?.exp * 1000 < Date.now()) {
        props.logout();
      }
    }
  }, [location, props]);

  return null;
};

export const stringFirstLetterToUpperCase = (string) =>
  string.charAt(0).toUpperCase() + string.slice(1);

export const range = (start, stop, step = 1) => {
  // console.log({ start, stop, step });
  if (typeof stop == 'undefined') {
    // one param defined
    stop = start;
    start = 0;
  }
  const result = [];
  for (let i = start; step > 0 ? i < stop : i > stop; i += step) {
    result.push(i);
  }
  return result;
};

export const EditableCell = ({
  editing,
  dataIndex,
  title,
  inputType,
  record,
  index,
  children,
  ...restProps
}) => {
  const inputNode =
    inputType === 'text' ? (
      <Input placeholder={`Enter  ${dataIndex}`} />
    ) : (
      <Select placeholder={`Select  ${dataIndex}`} options={record?.list} />
    );
  return (
    <td {...restProps}>
      {editing ? (
        <Form.Item
          name={dataIndex}
          className='m-0'
          rules={[{ required: true }]}
        >
          {inputNode}
        </Form.Item>
      ) : (
        children
      )}
    </td>
  );
};

export const mergedColumns = ({ columns, isEditing }) => {
  return columns.map((col) => {
    if (!col.editable) {
      return col;
    }
    return {
      ...col,
      onCell: (record) => {
        if ('list' in col) {
          record = { ...record, list: col.list };
        }
        return {
          record,
          inputType: 'list' in col ? 'select' : 'text',
          dataIndex: col.dataIndex,
          title: col.title,
          editing: isEditing(record),
        };
      },
    };
  });
};

export const dateTimeFormatter = (date) => {
  if (date) {
    return `${new Date(date).toDateString()} - ${new Date(
      date
    ).toLocaleTimeString()}`;
  }
};

export const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => {
      console.log('onload');
      resolve(reader.result);
    };
    reader.onerror = (error) => {
      console.log('onerror');
      reject(error);
    };
  });

export const validateMessages = {
  required: '${label} is required!',
  types: {
    email: '${label} is not a valid email!',
    number: '${label} is not a valid number!',
    integer: '${label} is not a valid integer!',
  },
  number: {
    range: '${label} must be between ${min} and ${max}',
  },
};

export const makeUrlFromIdOrIds = (url, idOrIds, action) => {
  if (action) {
    if (!Array.isArray(idOrIds)) {
      return `${url}?id=${idOrIds}&action=${action}`;
    } else {
      idOrIds = idOrIds.map((id) => `id=${id}`).join('&');
      return `${url}?${idOrIds}&action=${action}`;
    }
  } else {
    if (!Array.isArray(idOrIds)) {
      return `${url}?id=${idOrIds}`;
    } else {
      idOrIds = idOrIds.map((id) => `id=${id}`).join('&');
      return `${url}?${idOrIds}`;
    }
  }
};

export const makeFiltersQueryStringFromFiltersObjects = (filters) => {
  let filtersArray = [];
  for (const [key, value] of Object.entries(filters)) {
    if (value) {
      const filterString = value.map((val) => `${[key]}=${val}`).join('&');
      filtersArray.push(filterString);
    }
  }
  if (filtersArray.length === 0) {
    return '';
  } else if (filtersArray.length === 1) {
    return `&${filtersArray[0]}`;
  } else {
    return `&${filtersArray.join('&')}`;
  }
};

export const removeWhiteSpacesFromString = (valuesObject) => {
  if (valuesObject) {
    return Object.fromEntries(
      Object.entries(valuesObject).map(([key, value]) =>
        typeof value === 'string' ? [key, value.trim()] : [key, value]
      )
    );
  }
};

export const getModifiedCities = (array, param) => {

  const modifiedArray = array?.map(({ id, name, state, isDeleted }) => {
    const nameWithState = `${getStateLabelById(state)} / ${name}`;
    return {
      value: id,
      [param]: isDeleted ? `${nameWithState} - Deleted` : nameWithState,
      ...(isDeleted ? { disabled: true } : { disabled: false })
    };
  });
  
  return modifiedArray.sort((a, b) => (a[param] > b[param]) ? 1 : ((b[param] > a[param]) ? -1 : 0))

};

export const getModifiedVehicleModels = (array, param) => {
  const modifiedArray = array?.map(({ id, name, isDeleted }) => {
    return {
      value: id,
      [param]: isDeleted ? `${name} - Deleted` : name,
      ...(isDeleted ? { disabled: true } : { disabled: false })
    };
  });

  return modifiedArray.sort((a, b) => (a[param] > b[param]) ? 1 : ((b[param] > a[param]) ? -1 : 0))
};

export const getModifiedVehicleMakers = (array, param) => {
  const modifiedArray = array?.map(({ id, name, vehicleType, isDeleted }) => {
    const nameWithVehicleType = `${getVehicleTypeLabelById(
      vehicleType
    )} / ${name}`;
    return {
      value: id,
      [param]: isDeleted
        ? `${nameWithVehicleType} - Deleted`
        : nameWithVehicleType,
      ...(isDeleted ? { disabled: true } : { disabled: false })
    };
  });

  return modifiedArray.sort((a, b) => (a[param] > b[param]) ? 1 : ((b[param] > a[param]) ? -1 : 0))
};

export const getModifiedVehicleColors = (array, param) => {
  const modifiedArray = array?.map(({ id, name, code, isDeleted }) => {
    return {
      value: id,
      [param]: isDeleted ? `${name} - Deleted` : name,
      ...(isDeleted ? { disabled: true } : { disabled: false })
    };
  });

  return modifiedArray.sort((a, b) => (a[param] > b[param]) ? 1 : ((b[param] > a[param]) ? -1 : 0))
};

export const getModifiedExpenseTypes = (array, param) => {
  const modifiedArray = array?.map(({ id, name, isDeleted }) => {
    return {
      value: id,
      [param]: `${isDeleted ? `${name} - Deleted` : `${name}`}`,
      ...(isDeleted ? { disabled: true } : { disabled: false })
    };
  });

  return modifiedArray.sort((a, b) => (a[param] > b[param]) ? 1 : ((b[param] > a[param]) ? -1 : 0))
};

export const formatDate = (date) => {
  let d = new Date(date),
    month = '' + (d.getMonth() + 1),
    day = '' + d.getDate(),
    year = d.getFullYear();
  if (month.length < 2) month = '0' + month;
  if (day.length < 2) day = '0' + day;
  return [year, month, day].join('-');
};

export const Total = ({ label, field, data }) => {
  if (data?.length > 0) {
    return (
      <div className='text-lg'>
        Total {label}:
        <span className='font-bold'>
          {' '}
          {new Intl.NumberFormat().format(
            data?.data.reduce((acc, row) => acc + row[field], 0)
          )}
        </span>
      </div>
    );
  }
};

export const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2,
});

export const counterFormatter = (value) => {
  if (value) {
    return value.split('_').join(' ');
  }
};

export const randomlyPickItem = (items) =>
  items[Math.floor(Math.random() * items.length)];



export const filterOption = (input, option) =>
  (option?.label ?? '').toLowerCase().includes(input.toLowerCase());
